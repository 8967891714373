<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'Register',
  mounted () {
    if (this.$route.query.returnUrl) {
      this.$cookies.set('connect-redirect', this.$route.query.returnUrl,'30d','/');
    }

    this.$auth.loginWithRedirect({
      screen_hint: 'signup',
      appState: { targetUrl: '/register-profile' }
    });
  }
}
</script>

<style lang="scss"></style>
